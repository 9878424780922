import { createSelector } from 'reselect';
import { LOADING_STATUS } from '@dbh/generic-redux-constants';
import { makeSelectCountryAndLocale, makeSelectLocationPathname, makeSelectQueryParameters, makeSelectReactRouterPageType } from '@dbh/routing-redux';
import { selectUserAreaInfo, makeReducerKeyPathForUserBookingsLoaded, makeSelectUserLoaded, USER_LOGIN_STATUS_CHANGED } from '@dbh/user-area-redux';
import { select, put, call, take, fork } from 'redux-saga/effects';
import { isServerSideRendering } from '@dbh/environment';
import { LOAD_WEBPAGES_SUCCESS } from '@dbh/webpages-events-and-constants-redux';
import { makeSelectWebpagesLoaded } from '@dbh/webpages-redux';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { handleSagaError } from '@dbh/redux-saga-extra';
import { request } from '@dbh/request-queue-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import { getTrailingNumberFromPathname } from '@dbh/urls';
import '@dbh/validation';
import { ApiRequestError } from '@dbh/request-errors';
import { YOUR_BOOKINGS_FILTER_TYPES, YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS, NUMBER_OF_BOOKINGS_PER_PAGE, userBookingsLoading, userBookingsLoadSuccess, userBookingsLoadFailed } from '@dbh/user-area-bookings-events-and-constants-redux';
import 'prop-types';
import { Map } from 'immutable';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Returns a selector that returns the loading status of the "user bookings".
 * @return {boolean} Are the "user bookings" loaded for the current country and
 * locale.
 */const makeSelectUserBookingsLoadingStatus=()=>createSelector(selectUserAreaInfo,makeSelectCountryAndLocale(),(a,b)=>{if(!a)return !1;const c=makeReducerKeyPathForUserBookingsLoaded(b.get("country"),b.get("locale"));return a.getIn(c,LOADING_STATUS.NONE)});var makeSelectUserBookingsLoadingStatus$1 = makeSelectUserBookingsLoadingStatus;

/**
 * Returns user bookings `API` query parameters.
 * @param {Object} options .
 * @param {string?} options.filterBy .
 * @param {string?} options.pageNumber .
 * @return {Immutable.Map} .
 */const buildUserBookingsQueryParameters=withConformsTo("buildUserBookingsQueryParameters",[],a=>{let{filterBy:b,pageNumber:c}=a;const d={_page:c,_perPage:NUMBER_OF_BOOKINGS_PER_PAGE,// As per business requirements, we sort by the day of the reservation
// in descending order.
_sortFields:"day",_sortOrders:"desc"};return b===YOUR_BOOKINGS_FILTER_TYPES.UPCOMING?Map({...d,dateStart:format(new Date,"yyyy-MM-dd"),state:YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS[YOUR_BOOKINGS_FILTER_TYPES.UPCOMING]}):b===YOUR_BOOKINGS_FILTER_TYPES.PAST?Map({...d,dateEnd:format(subDays(new Date,1),"yyyy-MM-dd"),state:YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS[YOUR_BOOKINGS_FILTER_TYPES.PAST]}):b===YOUR_BOOKINGS_FILTER_TYPES.CANCELLED?Map({...d,state:YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS[YOUR_BOOKINGS_FILTER_TYPES.CANCELLED]}):Map(d)});var buildUserBookingsQueryParameters$1 = buildUserBookingsQueryParameters;

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 *//**
 * Calls the `bookings` `API` with method `GET`. When such `API` is called with
 * no query parameters and the authentication token, it returns the bookings
 * of the current user. This saga should only be called when the user is signed
 * in (we throw otherwise).
 * @yields {SagaEventResult} .
 */function*handleUserBookingsLoadInvoked(){yield select(makeSelectUserLoaded());const b=yield select(makeSelectLocationPathname());const c=API_IDS.bookings,d=getTrailingNumberFromPathname(b)||"1",e=yield select(makeSelectQueryParameters()),f=e&&e.get("filterBy"),g=buildUserBookingsQueryParameters$1({filterBy:f||YOUR_BOOKINGS_FILTER_TYPES.UPCOMING,pageNumber:d}),h=yield select(makeSelectApiUrl(c,{queryParameters:g})),i=yield select(makeSelectSagaRequestOptions(c)),j=yield select(makeSelectCountryAndLocale()),k=j.get("country"),l=j.get("locale");yield put(userBookingsLoading({country:k,locale:l}));try{const a=yield call(request,h,i),{emptyResponse:b,requestAttributes:c,apiResponse:e}=a;if(b)throw new ApiRequestError(a);yield put(userBookingsLoadSuccess({requestAttributes:c,apiResponse:e,country:k,locale:l,pageNumber:d}));}catch(a){handleSagaError(a);const{requestAttributes:b}=a;yield put(userBookingsLoadFailed({requestAttributes:b,country:k,locale:l}));}}

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 */function*isUserReservationsPage(){const a=yield select(makeSelectReactRouterPageType());return a===PAGE_TYPES.UserReservationsPage}/**
 * When the status of the user becomes loaded, it invokes the loading of the
 * user bookings. This saga is called in server side rendering using a
 * `redux-saga` channel (@see `userAreaUserBookingsChannelWatcher`) and in the
 * browser by the `takeLatest` on the bottom.
 * @yields {SagaEventResult} .
 */function*handleShouldLoadUserBookings(){const a=yield select(makeSelectUserLoaded()),b=yield call(isUserReservationsPage);b&&a&&(yield call(handleUserBookingsLoadInvoked));}

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 *//**
 * Channels are required to perform "cross-saga communication" in `SSR`.
 * The `UserArea` saga sends `USER_LOGIN_STATUS_CHANGED`, and `Webpages` saga
 * sends `LOAD_WEBPAGES_SUCCESS`.
 * @param {Object} chan `redux-saga` channel.
 * @yields {SagaEventResult?} .
 */function*userAreaUserBookingsChannelWatcher(a){if(isServerSideRendering())for(;;){const b=yield take(a),{type:c}=b;if(c!==LOAD_WEBPAGES_SUCCESS&&c!==USER_LOGIN_STATUS_CHANGED)return;if(c===LOAD_WEBPAGES_SUCCESS){const a=yield select(makeSelectUserLoaded());if(!a)return}if(c===USER_LOGIN_STATUS_CHANGED){const a=yield select(makeSelectWebpagesLoaded());if(!a)return}yield fork(handleShouldLoadUserBookings);}}

/*
 *
 * Package: `@dbh/user-area-bookings-redux`.
 *
 */const importSagaPromises=[import('./index-3a8544f5.js'/* webpackChunkName: 'dbh.user-area-bookings-redux.sagas' */)];

export { handleShouldLoadUserBookings as a, handleUserBookingsLoadInvoked as h, importSagaPromises as i, makeSelectUserBookingsLoadingStatus$1 as m, userAreaUserBookingsChannelWatcher as u };
