import { is } from 'immutable';
import { useRef, useMemo } from 'react';
import '@dbh/hotels-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Memoizes prices to prevent unnecessary re-renders.
 * @param {Immutable.Map?} prices .
 * @return {Immutable.Map?} .
 */const useMemoizePrices=withConformsTo("useMemoizePrices",[],a=>{const b=useRef(a),c=useMemo(()=>a?is(b.current,a)?b.current:(b.current=a,a):a,[a]);return c});

export { useMemoizePrices as default };
