import { useContext, useMemo } from 'react';
import 'prop-types';
import { useIntl } from 'react-intl';
import CurrencyContext from '@dbh/currency-context';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _noop from 'lodash/noop';

/*
 *
 * Constants: `@dbh/use-formatted-price`.
 *
 */const DEFAULT_PRICE_FORMAT_OPTIONS={currencyDisplay:"symbol",currencyStyle:"currency"};

/**
 * Replaces the currency symbol with the provided one.
 * @param {Object} options .
 * @param {string} options.formattedPrice @example `120$`, `120,450.00$`.
 * @param {string?} options.currencySymbol .
 * @param {boolean?} options.removeWhiteSpace .
 * @return {string} .
 */const replaceCurrencySymbolInFormattedPrice=withConformsTo("replaceCurrencySymbolInFormattedPrice",[],a=>{let{formattedPrice:b,currencySymbol:c,removeWhiteSpace:d}=a;return c?d?b.replace(/[^0-9.,’]+/g,c):b.replace(/[^0-9^\s^.^,’]+/g,c):b});var replaceCurrencySymbolInFormattedPrice$1 = replaceCurrencySymbolInFormattedPrice;

const currencyStylePropType=_noop;const currencyDisplayPropType=_noop;

const useFormattedPrice=withConformsTo("useFormattedPrice",[],(a,b)=>{const c={...DEFAULT_PRICE_FORMAT_OPTIONS,...(b||null)},d=useContext(CurrencyContext),{formatNumber:e}=useIntl(),{currencyStyle:f,currencyDisplay:g,removeWhiteSpace:h}=c,i=d&&d.get("displayFloatingPointDigits"),j=d&&d.getIn(["currencyData","currencyCode"]),k=d&&d.getIn(["currencyData","currencySymbol"]),l=useMemo(()=>{if(!a||"number"!=typeof a||!j)return "";const b=i?2:0,c=e(a,{style:f,currency:j,currencyDisplay:g,minimumFractionDigits:b,maximumFractionDigits:b});return replaceCurrencySymbolInFormattedPrice$1({formattedPrice:c,currencySymbol:k,removeWhiteSpace:h})},[a,j,i,e,f,g,k,h]);return l});

export { DEFAULT_PRICE_FORMAT_OPTIONS, currencyDisplayPropType, currencyStylePropType, useFormattedPrice as default, replaceCurrencySymbolInFormattedPrice$1 as replaceCurrencySymbolInFormattedPrice };
